import m from "mithril";

import successModal from "../../../components/modals/success";

import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "../index.css";

const i18n = globalState.i18n.oauth2.linking;

const twitchDrops = {
    view() {
        const method = globalState.twitch_id ? "post" : "get";
        const action = globalState.twitch_id ?
            "/oauth2/disconnect-account?client_id=twitch_drops" :
            "/oauth2/init-account-linking?client_id=twitch_drops";

        return m("form",
            handleForm({
                method,
                action,
                onSuccess() {
                    if (globalState.twitch_id) {
                        globalState.modal.display(successModal, {
                            i18n : {
                                header : globalState.i18n.success,
                                body   : i18n.disconnect.twitchComplete
                            }
                        });

                        // updating state doesn't remove values that were previously on state..
                        // so just, manually do it here
                        globalState.twitch_id = null;
                    }
                }
            }),

            // IMPORTANT: if the location of twitch information changes,
            // makes sure to update the twitch info cleanup on logout
            globalState.features.twitch ?
                m("div", { class : css.application },
                    m("h4", "Twitch"),

                    m("button[type=submit]",
                        {
                            class        : css.twitch,
                            "data-test"  : globalState.twitch_id ? "disconnect-twitch" : "connect-twitch",
                            "aria-label" : globalState.twitch_id ? i18n.disconnect.twitch : i18n.connect.twitch
                        },
                        globalState.twitch_id ?
                            i18n.disconnect.plain :
                            i18n.connect.plain
                    ),

                    m("a", { href : globalState.get("urls.twitchInfo"), class : css.learn }, i18n.twitchInfo)
                ) :
                null
        );
    }
};

export default {
    view() {
        return m("div.module.pure-u-1", { class : css.module },
            m("h3", globalState.i18n.oauth2.accountLinking),

            m("ul",
                m("li", m(twitchDrops))
            )
        );
    }
};
