"use strict";

const form = require("Form");

const sigRegex = /[a-zA-Z0-9\/\=\+]{28}/;

const otp       = [
    "value",
    "digits",
    {
        test       : "length",
        length     : 6,
        comparison : "=="
    }
];
const smsOtp    = [
    "value",
    "digits",
    {
        test       : "length",
        length     : 5,
        comparison : "=="
    }
];
const password  = [
    "value",
    {
        test   : "length",
        length : 8
    },
    {
        test       : "length",
        comparison : "<=",
        length     : 64
    }
];
const password2 = {
    test  : "compare",
    field : "password"
};

form.add("phoneFormat", o => {
    if (!o._value) {
        return false;
    }

    // keep only numbers
    const len = o._value.replace(/[^0-9]/gi, "").length;

    // TODO : 20 is something I made up, figure out what the real max length should be
    return len >= 10 && len <= 20;
});

form.add("codeFormat", o => {
    // the key is optional
    if (!o._value) {
        return true;
    }

    return o._value.replace(/[^A-Z0-9]/gi, "").length === 25;
});

form.add("displayname", o => {
    const value = String(o._value || "").trim();
    let valid   = !(/\s{2,}/.test(value));

    if (valid) {
        valid = /^[A-Z\s]{3,27}$/gi.test(value);
    }

    return valid;
});

form.add("openidResponse_nonce", o => {
    // o._value could have multiple "Z", so .split("Z") won't work
    // eg: 2020-10-03T00:11:11ZHMAvkZH/fIBJS/5D4oTpb8euLmI=
    const idx  = o._value.indexOf("Z");
    const date = o._value.slice(0, idx + 1);
    const sig  = o._value.slice(idx + 1);

    return !isNaN(Date.parse(date)) && sigRegex.test(sig);
});

// eg: sewHMtWkgYtF6UKIYi2H2Zq/5oo=
form.addRegex("openidSig", sigRegex);

form.addRegex("openidId", /https:\/\/steamcommunity\.com\/openid\/id\/[0-9]+/);

form.add("openidReturn_to", o => {
    const [ url ] = o._value.split("?");

    return [
        "http://account.local.ncplatform.net/login/steam/return",
        "https://account-dev.ncplatform.net/login/steam/return",
        "https://account-stage.ncplatform.net/login/steam/return",
        "https://account.arena.net/login/steam/return"
    ].includes(url);
});

form.add("lang", o => [ "en", "de", "es", "fr" ].includes(o._value));


const schemas = {

    login : {
        email    : "login",
        password : "value"
    },

    // only used on the client
    login_captcha : {
        email     : "login",
        password  : "value",
        recaptcha : "value"
    },

    steamLogin : {
        "openid.ns" : {
            test       : "compare",
            compare    : "http://specs.openid.net/auth/2.0",
            comparison : "==="
        },
        "openid.mode" : {
            test       : "compare",
            compare    : "id_res",
            comparison : "==="

        },
        "openid.op_endpoint" : {
            test       : "compare",
            compare    : "https://steamcommunity.com/openid/login",
            comparison : "==="
        },
        "openid.claimed_id"     : "openidId",
        "openid.identity"       : "openidId",
        "openid.return_to"      : "openidReturn_to",
        "openid.response_nonce" : "openidResponse_nonce",
        "openid.assoc_handle"   : {
            test       : "compare",
            compare    : "1234567890",
            comparison : "==="
        },
        "openid.signed" : {
            test       : "compare",
            compare    : "signed,op_endpoint,claimed_id,identity,return_to,response_nonce,assoc_handle",
            comparison : "==="
        },
        "openid.sig" : "openidSig",
        redirect_uri : {
            test     : "value",
            optional : "true"
        }
    },

    login_wait_totp : {
        otp       : otp,
        allowlist : {
            test     : "value",
            optional : true
        }
    },

    login_wait_sms : {
        otp       : smsOtp,
        allowlist : {
            test     : "value",
            optional : true
        }
    },

    login_wait_email : {
        otp : [
            "value",
            "digits",
            {
                test       : "length",
                length     : 5,
                comparison : "=="
            }
        ],
        allowlist : {
            test     : "value",
            optional : true
        }
    },

    register : {
        code  : "codeFormat",
        email : [
            "value",
            "email"
        ],
        password    : password,
        password2   : password2,
        displayname : [
            "value",
            "displayname"
        ],
        eula : {
            test : "value"
        }
    },

    register_captcha : {
        code  : "codeFormat",
        email : [
            "value",
            "email"
        ],
        password    : password,
        password2   : password2,
        displayname : [
            "value",
            "displayname"
        ],
        eula : {
            test : "value"
        },
        recaptcha : "value"
    },

    email : {
        email : [
            "login"
        ]
    },

    "add-code" : {
        code : [
            "value",
            "codeFormat"
        ]
    },

    "totp-link" : {
        otp       : otp,
        secret    : "value",
        allowlist : {
            test     : "passthrough",
            optional : true
        }
    },

    "totp-remove" : {
        otp1 : otp,
        otp2 : otp
    },

    "sms-verify" : {
        phone : [
            "value",
            "phoneFormat"
        ],
        countryCode : [
            "value",
            "digits"
        ],
        type : "value"
    },

    "sms-link" : {
        allowlist : {
            test     : "passthrough",
            optional : true
        },
        otp : smsOtp
    },

    "sms-remove" : {
        otp1 : smsOtp
    },

    "link-accounts" : {
        email    : "email",
        password : "value"
    },

    "password-reset-totp" : {
        otp : otp
    },

    "password-reset-sms" : {
        otp : smsOtp
    },

    "password-reset-recovery-keycharacter" : {
        character : [
            "value",
            {
                test   : "length",
                length : 3
            }
        ],
        key : [
            "value",
            "codeFormat"
        ]
    },

    "password-reset-recovery-character" : {
        character : [
            "value",
            {
                test   : "length",
                length : 3
            }
        ]
    },

    "password-reset" : {
        email : "email"
    },

    "password-reset-confirm" : {
        email : {
            test     : "email",
            optional : true
        },
        password  : password,
        password2 : password2
    },

    "change-pw" : {
        old       : "passthrough",
        password  : password,
        password2 : password2
    },

    "change-displayname" : {
        displayname : [
            "value",
            "displayname",
            // "different" added when form created
        ],
    },

    "change-accountname" : {
        email : [
            "value",
            "email",
            // "different" added when form created
        ],
        password : password,
    },

    "change-realname" : {
        realname : [
            "passthrough"
        ]
    },

    "change-phone" : {
        phone : [
            {
                test     : "phoneFormat",
                optional : true
            }, {
                test       : "length",
                comparison : "<=",
                length     : 20
            }
        ]
    },

    unsubscribe : {
        email : "email"
    },

    commPrefs : {
        email : {
            test     : "email",
            optional : true
        },
        anet : [
            "value",
            "boolString"
        ],
        gw2 : [
            "value",
            "boolString"
        ],
        gw : [
            "value",
            "boolString"
        ],
        signupUrl : "value",
        lang      : "lang"
    },

    commPrefsSignup : {
        email     : "email",
        signupUrl : "value"
    },

    apikey : {
        name : "value"
    }
};

module.exports = schemas;
