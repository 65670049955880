import m from "mithril";

import globalState from "../../../state";

import css from "./gift.css";

const i18n = globalState.i18n.settings.gift;

export default {
    view() {
        const show = globalState.commPrefs.showIncentive;

        return show ?
            m("div", { class : css.settingsGift },
                m("h4", i18n.header),

                m("div",
                    m("p", m.trust(i18n.copy)),

                    m("div",
                        m("img", {
                            width  : 126,
                            height : 117,
                            src    : "https://account.staticwars.com/img/newsletter-incentive.png",
                            alt    : ""
                        })
                    )
                ),

                m("p", { class : css.small }, i18n.disc)
            ) :
            null;
    }
};
