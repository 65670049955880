import globalState from "../state";

export default function getErrMsg(err) {
    const errorObj   = err.error || { message : "" };
    const errMsgPath = (errorObj.message || "").includes("i18n") ?
        errorObj.message :
        `i18n.errors.${errorObj.message}`; // error.message could be path, eg "productKey.invalid-key""

    return {
        /* program:dev */
        devReason : errorObj.devReason,
        message   : err.message,
        /* /program:dev */
        errMsg    : globalState.get(errMsgPath) || globalState.i18n.errors.general
    };
}
