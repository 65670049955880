import m from "mithril";

import addCode      from "../../components/modals/add-code";
import linkAccounts from "../../components/modals/link-accounts";

import globalState from "../../state";

import gw2Downloads from "./components/gw2-downloads";

import css from "./index.css";

export default {
    view() {
        const gcI18n = globalState.i18n.gameContent;
        const p4f    = !globalState.get("gw2Features.full.owned");
        const noGw2  = !globalState.gw2Features && globalState.features;

        return [
            m("div.pure-g",
                {
                    class       : css.gameContent,
                    "data-test" : "contentContainer"
                },

                // top heading and Add A Code
                m("div.pure-u-1.pure-u-md-19-24", { class : css.contentHd },
                    m("button.modLink.floatRight", {
                            class : css.addCode,
                            onclick() {
                                globalState.modal.display(addCode);
                            }
                        },
                        gcI18n.openCode
                    )
                ),

                m("div.pure-u-1.pure-u-md-19-24", { class : css.expandedModule },
                    m("div", { class : css.gameBd },

                        // Game header
                        m("h3", { class : css.gcHeading },
                            gcI18n[p4f ? "p4f" : "gw2"].title
                        ),

                        // links
                        m("div", { class : css.gameLinks },
                            // Upgrade
                            globalState.missingExpansions.length ?
                                m("a", {
                                        class  : css.upIcon,
                                        href   : noGw2 ? "/register?alt=gw2" : globalState.buyLink,
                                        config : noGw2 && m.route,
                                        target : !noGw2 && "_blank",
                                        rel    : !noGw2 && "noopener noreferrer"
                                    },
                                    noGw2 ? globalState.i18n.register.link : gcI18n.upgrade
                                ) :
                                null,

                            // Visit Site
                            m("a", {
                                    class : css.siteIcon,
                                    href  : gcI18n.gw2.url
                                },
                                gcI18n.site
                            )
                        ),

                        // client download links
                        globalState.gameList.length ?
                            m(gw2Downloads) :
                            null
                    )
                ),


                globalState.remoteRights !== "None" ?
                    m("div.pure-u-1.pure-u-md-19-24", { class : css.featuresModule },

                        m("h3", `${globalState.gameCode} ${gcI18n.features}`),
                        m("table", { class : "table" },
                            m("thead",
                                m("tr",
                                    m("th", "id"),
                                    m("th", "count"),
                                    m("th", "max"),
                                    m("th", "name")
                                )
                            ),
                            m("tbody",
                                globalState.accountFeatures.map(({ id, count, max_count, name }) =>
                                    m("tr",
                                        m("td", id),
                                        m("td", count),
                                        m("td", max_count),
                                        m("td", name)
                                    )
                                )
                            )
                        )
                    ) :
                    null,

                // Add A Code
                m("div.module.pure-u-1.pure-u-md-19-24", { class : css.module },
                    m("button", {
                            class : css.openCode,
                            onclick(e) {
                                e.preventDefault();
                                globalState.modal.display(addCode);
                            }
                        },

                        m("h3", { class : css.gcHeading },
                            gcI18n.openCode
                        )
                    )

                ),

                // Link a Guild Wars Account
                !globalState.hasGw1 && !globalState.isSteamUser ?
                    m("div.module.pure-u-1.pure-u-md-19-24", { class : css.module },
                        m("button", {
                                class : css.openLink,
                                onclick(e) {
                                    e.preventDefault();
                                    globalState.modal.display(linkAccounts);
                                }
                            },
                            m("h3", { class : css.gcHeading },
                                gcI18n.openLink
                            )
                        )
                    ) :
                    null
            )
        ];
    }
};
