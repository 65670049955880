import m       from "mithril";
import { sub } from "template";

import globalState from "../../state";

import css from "./index.css";

import getSteamId from "getSteamId";

const i18n = globalState.i18n.login.error.steam.support;

export default {
    oninit({ state }) {
        state.steamId      = getSteamId(globalState.user.login_name);
        state.displayName  = globalState.user.user_name;
        state.emailParams  = m.buildQueryString({
            subject : "Steam support request",
            body    :
`Steam ID: ${state.steamId}
Display name: ${state.displayName}
Support ID: ${globalState.user.user_id}`
        });
        state.supportEmail = `<a href='mailto:${globalState.supportEmail}?${state.emailParams}'>${decodeURIComponent(globalState.supportEmail)}</a>`;
        // m("a", {
        //     href : `mailto:${globalState.supportEmail}?${state.emailParams}`
        // }, decodeURIComponent(globalState.supportEmail));
    },

    view({ state }) {
        return m("div.module.pure-u-1.pure-u-md-19-24",
            m("h3", i18n.header),
            m("p", m.trust(sub(i18n.p, { supportEmail : state.supportEmail }))),

            m("ul", { class : css.list },
                m("li", i18n.li.steamId, state.steamId),
                m("li", i18n.li.displayName, state.displayName),
                m("li", i18n.li.supportId, globalState.user.user_id)
            )
        );
    }
};
