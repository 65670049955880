import m from "mithril";

import a       from "../../components/anchor";
import addCode from "../../components/modals/add-code";

import emailVerify from "../security/email/email-verify-link";

import game    from "./components/game";
import upgrade from "./components/upgrade";

import globalState from "../../state";

import css from "./index.css";

const pageI18n = globalState.i18n.overview;
const gcI18n   = globalState.i18n.gameContent;

export default {
    view() { // eslint-disable-line complexity
        return m("div.pure-g[data-test=overviewContainer]", { class : css.overview },
            m("div.pure-u-1.pure-u-md-1-4", { class : css.sideColumn },
                m("div.pure-u-1.pure-u-sm-20-24.pure-u-md-1", { class : css.module },
                    m("h3", pageI18n.personal.title),

                    // personal info
                    m("div.modFieldStack",
                        pageI18n.personal.email,

                        !globalState.isSteamUser ?
                            m("div.modLink",
                                m(emailVerify)
                            ) :
                            null,

                        m("div.modVal",
                            decodeURIComponent(globalState.user.login_name)
                        )
                    ),

                    m("div.modFieldStack",
                        pageI18n.personal.display,
                        m("div.modVal",
                            decodeURIComponent(globalState.user.user_name)
                        )
                    ),

                    m("div.modFieldStack",
                        pageI18n.personal.region,
                        m("div.modVal",
                            pageI18n.personal[`region-${globalState.user.user_center}`] || ""
                        )
                    )
                )
            ),

            m("div.pure-u-1.pure-u-md-1-2", { class : css.middleColumn },
                m("div.pure-u-1.pure-u-md-1.pure-u-sm-20-24", { class : css.module },
                    m(a, {
                            href  : "/content",
                            class : css.manage
                        },
                        gcI18n.manage
                    ),

                    m("h3", pageI18n.accounts),

                    !globalState.loading || globalState.gw2Features ?
                        m("ul",
                            m(game, {
                                game  : "gw2",
                                owned : globalState.hasGw2,
                                cta   : globalState.hasGw2 ? gcI18n.downloadClient : globalState.i18n.register.link,
                                href  : globalState.hasGw2 ? "/content" : "/register"
                            }),

                            Object.entries(globalState.gw2Features)
                                .filter(([ key, value ]) => value.isExp && ![ "hot", "pof" ].includes(key))
                                .reverse()
                                .map(([ key, value ]) => m(game, {
                                    game  : key,
                                    owned : value.owned,
                                    pp    : value.isPP,
                                    inc   : !value.owned
                                })),

                            m(game, {
                                game  : "pof",
                                owned : globalState.gw2Features.pof?.owned,
                                inc   : !globalState.gw2Features.pof?.owned && !globalState.gw2Features.hot?.owned
                            }),

                            m(game, {
                                game  : "hot",
                                owned : globalState.gw2Features.hot?.owned,
                                cta   : globalState.get("i18n.overview.hot.buy"),
                                inc   : !globalState.gw2Features.pof?.owned && !globalState.gw2Features.hot?.owned
                            }),

                            m(game, {
                                game  : "gw1",
                                owned : globalState.hasGw1
                            })
                        ) :
                        null
                )
            ),

            m("div.pure-u-1.pure-u-md-1-4", { class : css.sideColumn },
                m("div.pure-u-1.pure-u-md-1.pure-u-sm-20-24", { class : css.module },
                    m("h3", pageI18n.links.title),

                    m("nav", { class : css.links },
                        !globalState.isSteamUser ?
                            [
                                m(a, { href : "/content", }, pageI18n.links.link1),
                                m("a", { href : globalState.get("urls.securingAccount") },
                                    pageI18n.links.link2
                                )
                            ] :
                            null,

                        m("a", { href : globalState.get("urls.accountHelp") },
                            pageI18n.links.link4
                        )
                    ),

                    m("button.pure-button.pure-button-primary.pure-u", {
                            class       : css.codeBtn,
                            "data-test" : "openRedeemCode",
                            onclick(e) {
                                e.preventDefault();
                                globalState.modal.display(addCode);
                            }
                        },
                        pageI18n.links.button
                    )
                ),

                m(upgrade)
            )
        );
    }
};
