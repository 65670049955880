import m         from "mithril";
import cssjoiner from "cssjoiner";

import a from "../../../anchor";

import hide from "../../../../util/hide-components";

import globalState from "../../../../state";

import css from "./index.css";

const defaultSections = [{
    key : "overview",
    url : "/overview"
}, {
    key : "settings",
    url : "/settings"
}, {
    key : "security",
    url : "/security/settings"
}, {
    key : "content",
    url : "/content"
}, {
    key : "applications",
    url : "/applications"
}];

export default {
    view({ state }) {
        const route = m.route.get();
        // can't filter for steam in oninit - component loads before user data.
        const sections = defaultSections.filter(({ key }) => !(globalState.isSteamUser && key === "security"));
        const width    = 100 / sections.length;

        const selectedIndex = sections.findIndex(section =>
            // we want to direct to /security/settings, but we want the nav to
            // display security for any /security/... route
            (section.url === "/security/settings" ?
                route.startsWith("/security") :
                route.startsWith(section.url)
            )
        );

        const noIdx = selectedIndex === -1;

        return hide() ?
            null :
            m("div", { class : css.nav },

                m("div", { class : css.mobileNavHeader },
                    !noIdx && m("h2", globalState.i18n.nav[sections[selectedIndex].key]),

                    m("button", {
                        class           : css.menuIcon,
                        "aria-haspopup" : "true",
                        "aria-expanded" : state.showMobileNav,
                        "aria-label"    : globalState.i18n.ui.menu,
                        onclick() {
                            globalState.showMobileNav = !globalState.showMobileNav;
                        }
                    })
                ),

                m("nav", { class : cssjoiner(
                            css[`sel-${selectedIndex}`],
                            [ globalState.showMobileNav, css.showMobileNav ]
                        )
                    },

                    sections.map(({ key, url }, idx) =>
                        m(a, {
                                class       : idx === selectedIndex ? css.selected : "",
                                "data-test" : `${key}Nav`,
                                href        : url,
                                style       : { flexBasis : `${width}%` }
                            },
                            globalState.i18n.nav[key]
                        )
                    ),

                    m("hr", { style : { width : `calc(${width}% - 1px)` } })
                )
            );
    }
};
