import m       from "mithril";
import { sub } from "template";

import detectOs        from "../../../../util/detect-os";
import getDownloadLink from "../../../../util/get-download-link";
import gamesight       from "../../../../util/gamesight";

import globalState from "../../../../state";

import css from "../../index.css";

const gcI18n   = globalState.i18n.gameContent;
const eulaI18n = globalState.i18n.eulaAck;

const button = {
    view({ attrs }) {
        let osKey = attrs.client.os;

        if (attrs.client.test) {
            osKey = `${attrs.client.os}test`;
        } else if (attrs.client.beta) {
            osKey = `${attrs.client.os}beta`;
        }

        return m("a", {
                class       : css[attrs.client.os],
                "data-test" : "downloadClient",
                href        : getDownloadLink(attrs.client),
                onclick() {
                    gamesight("send", "account_anet_download");
                }
            },
            gcI18n.download[osKey],

            m("br"),

            gcI18n.download[`${attrs.client.bits}bit`]
        );
    }
};

export default {
    oninit({ state }) {
        const os = detectOs();

        state.clients             = [];
        state.nonGw2BranchClients = [];

        globalState.gameList.forEach(client => {
            if (client.branch !== "gw2") {
                state.nonGw2BranchClients.push(client);

                return;
            }

            if (client.os === os.type && client.bits === os.bits) {
                state.bestClient = client;

                return;
            }

            state.clients.push(client);
        });

        if (!state.bestClient) {
            state.bestClient = state.clients.shift();
        }
    },

    view({ state }) {
        return m("div", { class : css.gw2Content },

            globalState.isSteamUser ?
                [
                    m("h3", gcI18n.downloads.steam.title),
                    m("p", gcI18n.downloads.steam.p),
                    m("a", { href : globalState.get("urls.steamInstall") }, gcI18n.downloads.steam.a)
                ] :
                [
                    m("h3", gcI18n.downloads.title),

                    // "Game Client - NA & EU"
                    m("div", { class : css.clientInfo },
                        m("span", { class : css.dlTitle },
                            gcI18n.download.client,
                            " - "
                        ),
                        gcI18n.download.location
                    ),

                    // client download link
                    m(button, { client : state.bestClient }),

                    // EULA Ack
                    m("p", { class : css.eulaAck },
                        m.trust(sub(eulaI18n, { downloadText : gcI18n.download.win }))
                    )
                ],

            // min sys reqs
            m("p", { class : css.minSpec },
                m("a", { href : globalState.get("urls.minSysReqs") }, gcI18n.minimum[state.bestClient.os])
            ),

            // internal/alpha download links
            state.nonGw2BranchClients.length ?
                [
                    m("h3", { class : css.branchTitle },
                        // TODO: Untranslated string, fine since it's alpha-only
                        `${gcI18n.branches.title} - Internal`
                    ),

                    m("div", { class : css.branchClients },
                        state.nonGw2BranchClients.map(client =>
                            m("a", {
                                    class : css.dlBtn,
                                    href  : getDownloadLink(client)
                                },
                                `${client.branch.toUpperCase()} ${client.os}${client.bits}`
                            )
                        )
                    )
                ] :
                null
        );
    }
};
