import handleForm from "../../util/handle-form";

import passthroughParams from "./wait/components/passthrough-params";

import globalState from "../../state";

import css from "./index.css";

const loginI18n = globalState.i18n.login;

const logos = {
    epic  : "https://account.staticwars.com/pages/login/epic-white.png",
    steam : "https://account.staticwars.com/pages/login/steam.svg"
};

/**
 * Create a login redirect URL
 * @param {{ platform : "steam" | "epic" }} args
 */
function composeRedirect({ platform }) {
    const base        = `/login/${platform}`;
    const redirectUri = m.route.param("redirect_uri");

    return redirectUri ? `${base}?redirect_uri=${encodeURIComponent(redirectUri)}` : base;
}

/**
 * @type {import("mithril").Component<{
 *  enabled : boolean,
 *  platform : "steam" | "epic"
 * }, null>}
 */
export default {
    view(vnode) {
        const { enabled, platform } = vnode.attrs;

        if (!enabled) {
            return null;
        }

        return m("form",
            handleForm({
                method : "POST",
                action : `/login/${platform}`
            }),

            // gamecode, redirect_uri, fakeip
            m(passthroughParams),

            m("button", {
                    "data-test" : `${platform}-login`,
                    href        : composeRedirect({ platform }),
                    class       : css.thirdPartySignIn
                },
                m("img", {
                    src : logos[platform],
                    alt : loginI18n[`signIn${platform}`]
                }),

                m("span", loginI18n[`signIn${platform}`])
            )
        );
    }
};
