import m         from "mithril";
import mailcheck from "mailcheck";
import cssjoiner from "cssjoiner";

import { sub } from "template";

import globalState from "../../../state";

import schemas from "../../../preload/schemas";

import css from "../index.css";

const i18n = globalState.i18n.settings.commPrefs;

export default {
    view({ state, attrs }) {
        const valid = attrs.isValid(schemas.commPrefsSignup);

        return [
            m("div", { class : css.email },
                m("input", {
                    type        : "email",
                    name        : "email",
                    placeholder : i18n.label,

                    "data-test" : "commPrefs-signup",

                    oninput({ target }) {
                        state.suggested = mailcheck.run({ email : target.value });
                    },
                    onfocus() {
                        state.showSuggested = false;
                    },
                    onblur() {
                        state.showSuggested = true;
                    }
                }),
                state.showSuggested && state.suggested ?
                    m("div", { class : css.suggestion },
                        m.trust(sub(i18n.suggestion, { email : state.suggested.full }))
                    ) :
                    null
            ),

            m("button[type=submit]", {
                    class : cssjoiner(
                        "pure-button",
                        "pure-button-primary",
                        [ !valid, css.saved ]
                    ),
                    disabled    : !valid,
                    "data-test" : "commPrefs-save"
                },
                i18n.signUp
            )
        ];
    }
};
