import m          from "mithril";
import objectPath from "object-path";

import form from "Form";

import schemas from "../preload/schemas";

import parseUri      from "./parse-uri";
import getFormValues from "./get-form-values";
import getErrMsg     from "./get-err-msg";

import globalState  from "../state";
import { postForm } from "./post-form";

function removeEmptyErrors({ errors }) {
    Object.keys(errors).forEach(err => {
        if (!errors[err]._value) {
            delete errors[err];
        }
    });
}

/**
 * Add iovation blackbox data to login and register forms
 * @param {object} formValues object to attach iovation blackbox data to
 * @param {string} path form action, used to determine if blackbox data is required
 * @returns {object} formValues, with or without blackbox data
 */
function addBlackbox(formValues, path) {
    if (path.indexOf("/login") !== 0 && path.indexOf("/register") !== 0) {
        return formValues;
    }

    const getBlackbox = objectPath.get(window, "IGLOO.getBlackbox");

    if (getBlackbox) {
        formValues.blackbox = getBlackbox().blackbox;
        formValues.finished = getBlackbox().finished;
    }

    return formValues;
}

function submitForm(config, e) {
    e.preventDefault();

    // e comes from the currently focused element, this can be an input when using ctrl+enter to submit
    // currentTarget should always be the submitted <form>
    const formElement = e.currentTarget;
    const url         = parseUri(formElement.action);
    const formValues  = getFormValues(formElement);

    if (formValues._formName in schemas) {
        const result = form.validate(formValues, schemas[formValues._formName]);

        if (!result.valid) {
            // force display of errors since they are normally debounced
            globalState.form.setForm(result);
            globalState.form.setErrorMessage(globalState.i18n.errors.invalidFields);

            m.redraw();

            return;
        }
    }

    // reg form captcha can disable form
    if ("disabled" in formElement.attributes) {
        return;
    }

    globalState.form.clearErrorMessage();

    globalState.loading = true;

    m.request({
        method : formElement.method,
        url    : `${url.path}.json${url.search}`,
        data   : addBlackbox(formValues, url.path),

        // makes sure response remains JSON data for mithril
        deserialize(response) {
            let result;

            try {
                result = JSON.parse(response);
            } catch (err) {
                result = response;
            }

            return result;
        }
    })
    .then(res => {
        if (res.redirect) {
            if (res.redirect.method === "POST") {
                postForm(res.redirect);

                return;
            }

            // redirect is to a diff domain (forum, buy site)
            if (parseUri(res.redirect).host !== parseUri(window.location).host) {
                window.location = res.redirect;

                return; // we're done with acct site
            }

            // otherwise part of the app
            m.route.set(res.redirect);
        } else {
            globalState.update(res);

            m.redraw();
        }

        globalState.loading = false; // should this be in the else above?
        globalState.invalidateLoaded();

        if (config.onSuccess) {
            config.onSuccess(res);
        }
    })
    .catch(err => {
        const errMsg = getErrMsg(err);

        globalState.form.setErrorMessage(errMsg);
        globalState.loading = false;

        // unneeded?
        m.redraw();
    });
}

function validateAndDisplayErrors(formElement, debounce = false) {
    const formValues = getFormValues(formElement);

    const result = form.validate(formValues, schemas[formValues._formName]);

    removeEmptyErrors(result);

    // clear displayed errors if there aren't any to display
    if (!Object.keys(result.errors).length) {
        globalState.form.setForm(result);
    }

    if (debounce) {
        window.clearTimeout(globalState.form.debounceErrors[formValues._formName]);

        // make error visible
        globalState.form.debounceErrors[formValues._formName] = window.setTimeout(() => {
            globalState.form.setForm(result);

            m.redraw();
        }, 50);
    }

    return result;
}

function updateErrors(e) {
    e.preventDefault();

    return validateAndDisplayErrors(e.target.form, e.type === "input");
}

export default function handleForm(config = {}) {
    return Object.assign({
            oninput  : updateErrors,
            onchange : updateErrors,
            onsubmit : submitForm.bind(null, config),
            onkeydown(e) {
                if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                    submitForm(config, e);
                }
            }
        },
        config
    );
}
